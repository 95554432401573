export default defineNuxtRouteMiddleware((to) => {
  const removeLang = (word: string) => word.replace('/de/', '/')

  const pages = [
    '(/de/)(webinars/).+$',
    '(/de/)(careers|careers/).*',
    '(/de/)(roadmap|test-cases|partner-program|blog|partners|intake-api|features|report-issue)',
  ]

  const pattern = new RegExp(pages.join('|'), 'g')
  if (pattern.test(to.fullPath)) {
    return navigateTo(removeLang(to.fullPath), { redirectCode: 301 })
  }
})
