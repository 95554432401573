<template>
  <component
    :is="tagName"
    :id="id"
    :to="to"
    :address="address"
    :title="title || ''"
    type="button"
    :class="[
      'button',
      sizeClass,
      typeClass,
      typeDisabledClass,
      fullWidthClass,
      {
        'button--disabled': disabled,
        'button--icon': !$slots.text && !showOpenRoles,
        'button--icon-typo': $slots.text && $slots.icon,
        'button--shadow': hasShadow,
        'button--circle': isCircle,
      },
    ]"
    v-bind="$attrs"
    :disabled="disabled"
    :target="target"
    rel="noopener"
    @click="handleClick"
  >
  
  <span v-if="showOpenRoles">
   {{ 
    $t('common.open_roles', {
        number: numberOfOpenRoles,
      }) 
    }}
  </span>

    <span v-else class="button__content">
      <span
        v-if="$slots.icon"
        :class="{
          button__image: $slots.icon,
          'button__image--icon': !$slots.text,
        }"
      >
        <slot name="icon" />
      </span>
      <slot name="text" />
    </span>
  </component>
</template>

<script lang="ts" setup>
import type { MultilinkStoryblok } from '@/types'
import type { LeverData } from '@/types'

interface Props {
  small?: boolean
  outlined?: boolean
  ghost?: boolean
  link?: boolean
  href?: MultilinkStoryblok | null
  disabled?: boolean
  target?: string
  fullWidth?: boolean
  isCircle?: boolean
  hasShadow?: boolean
  id?: string | undefined
  title?: string
  to?: string
  showOpenRoles?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  small: false,
  outlined: false,
  ghost: false,
  link: false,
  href: null,
  disabled: false,
  target: '_self',
  fullWidth: false,
  isCircle: false,
  id: undefined,
  title: undefined,
  to: undefined,
  showOpenRoles: false
})

const emit = defineEmits<{
  'handle-click': []
}>()

const numberOfOpenRoles = ref(0)
const config = useRuntimeConfig()

if (props.showOpenRoles) {
  const { data } = await useFetch<LeverData[]>(
        `${config.public.leverCareersUrl}?&mode=json`,
  )
  numberOfOpenRoles.value = data.value.length || 0
}


const handleClick = () => {
  if (tagName.value === 'button') {
    emit('handle-click')
  }
}
const address = computed(() => {
  return props.href?.cached_url ? props.href : null
})
const tagName = computed(() => {
  return props.href?.cached_url || props.to
    ? resolveComponent('BaseLink')
    : 'button'
})
const sizeClass = computed(() => {
  return props.small ? 'button--small' : null
})
const typeClass = computed(() => {
  if (props.outlined) {
    return 'button--outlined'
  }
  if (props.ghost) {
    return 'button--ghost'
  }
  if (props.link) {
    return 'button--link'
  }
  return 'button--filled'
})

const typeDisabledClass = computed(() => {
  return props.href && props.disabled ? `${typeClass.value}-disabled` : null
})
const fullWidthClass = computed(() => {
  return props.fullWidth ? 'button--full-width' : null
})
</script>
