import type { ISbStoriesParams } from 'storyblok-js-client'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('i18n:beforeLocaleSwitch', async ({ newLocale, context }) => {
    const storyblokApi = useStoryblokApi()
    const language = newLocale === 'en' ? '' : newLocale
    const mainStore = useMainStore((context as any).$pinia)
    const config = useRuntimeConfig()
    const options: ISbStoriesParams = {
      version: config.public.storyblokApiVersion as 'draft' | 'published',
      language,
    }
    try {
      const [footerRes, promoRes] = await Promise.all([
        storyblokApi.get('cdn/stories/global/newfooter', options),
        storyblokApi.get('cdn/stories/global/floating-promo', options),
      ])
      mainStore.$patch({
        footer: footerRes.data,
        promo: promoRes.data,
      })
    } catch (err: any) {
      !err
        ? createError({
            statusCode: 404,
            message: 'Failed to receive promo data form api',
          })
        : createError({
            statusCode: err.status,
            message: err.message,
          })
    }
  })
})
