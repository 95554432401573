<template>
  <NuxtLayout name="default" navbar-color="#fff">
    <main class="page-404">
      <div class="container page-404__container">
        <BaseNuxtImage
          src=""
          class="fluid-image page-404__image"
          alt="Woman with a magnifying glass."
        />
        <h1 class="page-404__title">
          {{ $t('common.page_missing.title') }}
        </h1>
        <p class="page-404__subtitle">
          {{ $t('common.page_missing.subtitle') }}
        </p>
        <BaseButton
          :title="$t('common.page_missing.cta')"
          :href="{ cached_url: language, linktype: 'url', url: '/', id: '' }"
        >
          <template #text>
            {{ $t('common.page_missing.cta') }}
          </template>
        </BaseButton>
      </div>
    </main>
  </NuxtLayout>
</template>

<script setup lang="ts">
const { t, locale } = useI18n()
const language = computed(() => {
  return locale.value === 'en' ? '/' : `/${locale.value}`
})

onBeforeUnmount(() => {
  reloadNuxtApp()
})

useHead({
  title: t('common.page_missing.meta.title'),
  meta: [
    {
      vmid: 'description',
      name: 'description',
      content: t('common.page_missing.meta.description'),
    },
  ],
})
</script>

<style lang="scss">
.page-404 {
  margin-bottom: 126px;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__image {
    display: block;
    margin: 100px auto 40px;
  }

  &__title,
  &__subtitle {
    text-align: center;
  }

  &__title {
    margin-bottom: 16px;
  }

  &__subtitle {
    margin-bottom: 42px;
    font-size: var(--font-size-heading-3);
    color: var(--navy-700);
    font-weight: var(--font-weight-medium);
  }

  &__cta {
    margin: 0 auto 162px;
  }
}
</style>
