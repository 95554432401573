import { acceptHMRUpdate } from 'pinia'
import type { GlobalDataSource } from '@/types'

export const useDataSourceStore = defineStore('datasource', () => {
  const globalDatasource = ref<GlobalDataSource[]>([])
  const dataSourceValues = computed(() => {
    if (globalDatasource.value.length > 0) {
      return globalDatasource.value.reduce(
        (obj, item) => ({
          ...obj,
          ['%' + item.name + '%']: item.value,
        }),
        {},
      )
    }
    return [
      {
        name: 'error',
        value: 'SOMETHING WRONG WITH DATA SOURCE',
      },
    ]
  })

  return {
    globalDatasource,
    dataSourceValues,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDataSourceStore, import.meta.hot))
}
