import { acceptHMRUpdate } from 'pinia'

export const useNavStore = defineStore('nav', () => {
  const mainStore = useMainStore()

  // const simpleNavButtonText = computed(() => {
  //   return mainStore.storyData?.content?.button_text || 'Get in touch'
  // })

  const simpleNavButtonLink = computed(() => {
    const buttonLink = ref(mainStore.storyData?.content?.button_link || null)
    return {
      link: buttonLink.value,
      target: buttonLink.value?.target || '_self',
    }
  })

  // const naviColor = computed(() => {
  //   return mainStore.storyData?.content?.nav_color?.value || '#fff'
  // })


  return {
    // naviColor,
    // simpleNavButtonText,
    simpleNavButtonLink,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNavStore, import.meta.hot))
}
