export const excludedPagesDev = [
  '(/pl/)',
  '/(home|what-can-we-learn-about-people-using-symptom-checkers)',
  '(/de/?)events/shared-section',
  '(/de/?)product/intake-form',
  '(/de/?)(partner-program|test-cases|bsw|careers|prospects-registration-form|what-can-we-learn-about-people-using-symptom-checkers)',
  '(/de)?(experts|experts/)(.*)',
  '(/de)?(/reports/)(report-tags|report-tags/)(.*)',
  '(/de/?)(case-studies/covid19)',
]

export const excludedPages = [
  ...excludedPagesDev,
  '/(testfolder)',
  '(/de)?(/impartner)',
  '(events/representatives).*',
  '(webinars/experts).*',
  '(reports/report-tags).*',
  '(partners/tags-categories).*',
  '(global/).+$',
  '(/de/)(careers|careers/).+$',
  '(/de/)(roadmap|test-cases|partner-program|blog|partners|intake-api)',
]
