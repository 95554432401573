import type { DirectiveBinding } from 'vue'

type ExtendedHTMLElement = HTMLElement & {
  _clickOutside?: (e: MouseEvent) => void
}
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('click-outside', {
    mounted(el: ExtendedHTMLElement, binding: DirectiveBinding) {
      const { value } = binding
      const handler = (e: MouseEvent) => {
        if (!el.contains(e.target as Node)) {
          value()
        }
      }
      document.addEventListener('mouseup', handler)
      el._clickOutside = handler
    },
    getSSRProps() {
      return {}
    },
    unmounted(el: ExtendedHTMLElement) {
      document.removeEventListener(
        'click',
        el._clickOutside as EventListenerOrEventListenerObject,
      )
      delete el._clickOutside
    },
  })
})
