import { excludedPages, excludedPagesDev } from '@/utils/excluded-pages'
export default defineNuxtRouteMiddleware((to) => {
  const config = useRuntimeConfig()
  const pages =
    config.public.storyblokApiVersion !== 'draft'
      ? excludedPages
      : excludedPagesDev

  const pattern = new RegExp(pages.join('|'), 'g')

  if (pattern.test(to.fullPath)) {
    throw createError({
      statusCode: 404,
      message: 'Not Found',
    })
  }
})
