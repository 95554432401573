import { acceptHMRUpdate } from 'pinia'

export const useCacheStore = defineStore('cache', () => {
  const cacheVersion = ref(0)

  return {
    cacheVersion,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCacheStore, import.meta.hot))
}
