<script lang="ts" setup>
interface Props {
  src: string
  height?: string | number
  width?: string | number
  loading?: 'lazy' | 'eager'
  alt: string
}
const props = withDefaults(defineProps<Props>(), {
  height: undefined,
  width: undefined,
  loading: 'eager',
  alt: 'image alt',
})

const { intrinsicImageWidth, intrinsicImageHeight } = useBaseImage()

const imageSrc = computed(() =>
  props.src === undefined || props.src === null || props.src === ''
    ? 'https://a.storyblok.com/f/120667/410x372/b7844a6261/404_illustration.png'
    : props.src,
)

const imgIntrinsicHeight = computed(() => intrinsicImageHeight(imageSrc.value))
const imgIntrinsicWidth = computed(() => intrinsicImageWidth(imageSrc.value))

const imageHeight = computed(() =>
  props.height === undefined ? imgIntrinsicHeight.value / 2 : props.height,
)
const imageWidth = computed(() =>
  props.width === undefined ? imgIntrinsicWidth.value / 2 : props.width,
)

const sizes = computed(() => {
  return props.width === undefined
    ? `sm:${imgIntrinsicWidth.value / 3}
       md:${imgIntrinsicWidth.value / 2}
       lg:${imgIntrinsicWidth.value}`
    : `sm:${+props.width * 2}
        md:${+props.width * 2}
        lg:${+props.width * 2}`
})
</script>

<template>
  <NuxtImg
    format="webp"
    :width="imageWidth"
    :height="imageHeight"
    :loading="loading"
    :sizes="sizes"
    provider="storyblok"
    :src="imageSrc"
    :alt="alt"
  />
</template>
