import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const {
    public: { sentry },
  } = useRuntimeConfig()

  if (!sentry.sentryDsnPublic) {
    return
  }

  Sentry.init({
    enabled: process.env.NODE_ENV !== 'development',
    app: nuxtApp.vueApp,
    dsn: sentry.sentryDsnPublic,
    environment: sentry.environment,
    release: 'infermedica-com-fe@' + process.env.npm_package_version,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    // Configure this whole part as you need it!

    tracesSampleRate: 0.2, // Change in prod

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'http://localhost:3000/',
      'https://infermedica.com/',
      'https://dev.infermedica.com/',
    ],

    replaysSessionSampleRate: 1.0, // Change in prod
    replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
  })
})
