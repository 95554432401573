import validate from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.12.0_rollup@4.24.0_sass@1.79.4_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/validate.js";
import _1_45redirects_45global from "/app/middleware/1.redirects.global.ts";
import _2_45errors_45global from "/app/middleware/2.errors.global.ts";
import _3_45redirect_45trailing_45slash_45global from "/app/middleware/3.redirectTrailingSlash.global.ts";
import route_45global from "/app/middleware/route.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.12.0_rollup@4.24.0_sass@1.79.4_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _1_45redirects_45global,
  _2_45errors_45global,
  _3_45redirect_45trailing_45slash_45global,
  route_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "redirect-lp-pages": () => import("/app/middleware/redirectLpPages.ts")
}