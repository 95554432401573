<template>
  <NuxtLoadingIndicator color="#0068fa" />
  <div 
    @mouseenter.once="loadScripts"
    @touchstart.once="loadScripts"
  >
    <NuxtPage />
  </div>
</template>

<script setup lang="ts">
import { addScriptToHead } from '@/utils/helpers'
const config = useRuntimeConfig()

const loadScripts = () => {
  
    if (config.public.injectScripts === 'false') return;

    // HubSpot Script
    addScriptToHead('//js.hs-scripts.com/5731513.js')

    // Google Tag Manager Script
    const gtmInnerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-5TS4LJZ');
    `
    addScriptToHead('', gtmInnerHTML)  
}

</script>
