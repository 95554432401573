export const useAvailableLocalePath = () => {
  const { locale } = useI18n()
  const localeRoute = useLocaleRoute()

  const availableLocaleRoute = (link: string) => {
    const route = localeRoute(link, locale.value)
    return route != null ? route.path : link
  }

  return {
    availableLocaleRoute,
  }
}
