import revive_payload_client_M1Ns1Wm9c5 from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.12.0_rollup@4.24.0_sass@1.79.4_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_woTC8Eeq4i from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.12.0_rollup@4.24.0_sass@1.79.4_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_eVV48Upqcq from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.12.0_rollup@4.24.0_sass@1.79.4_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MfU0D3dslB from "/app/node_modules/.pnpm/nuxt-site-config@2.2.18_rollup@4.24.0_vite@5.4.8_vue@3.5.11/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import navigation_repaint_client_Tf7VSEVryC from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.12.0_rollup@4.24.0_sass@1.79.4_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_WdNYCVqePD from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.12.0_rollup@4.24.0_sass@1.79.4_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_FC38CBDKih from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.12.0_rollup@4.24.0_sass@1.79.4_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_nEb5lM6ozV from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.11/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_k8ERCDrff6 from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.12.0_rollup@4.24.0_sass@1.79.4_typescript@5.6.3_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_WoYiHY2EFc from "/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_rollup@4.24.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import switch_locale_path_ssr_uQxXoWhtBq from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.11/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_0T4Bi5RaJ6 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.11/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_kEmm3MA96i from "/app/node_modules/.pnpm/@storyblok+nuxt@6.0.13/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import motion_Fmqz9xzPjP from "/app/node_modules/.pnpm/@vueuse+motion@2.2.5_rollup@4.24.0_vue@3.5.11/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import clickOutsideDirective_JuSoe6vGeC from "/app/plugins/clickOutsideDirective.ts";
import fetchGlobalData_EOfhKKMol6 from "/app/plugins/fetchGlobalData.ts";
import onLanguageSwitch_Zq3lupusSn from "/app/plugins/onLanguageSwitch.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_M1Ns1Wm9c5,
  unhead_woTC8Eeq4i,
  router_eVV48Upqcq,
  _0_siteConfig_MfU0D3dslB,
  navigation_repaint_client_Tf7VSEVryC,
  check_outdated_build_client_WdNYCVqePD,
  chunk_reload_client_FC38CBDKih,
  plugin_vue3_nEb5lM6ozV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_k8ERCDrff6,
  nuxt_plugin_WoYiHY2EFc,
  switch_locale_path_ssr_uQxXoWhtBq,
  i18n_0T4Bi5RaJ6,
  plugin_kEmm3MA96i,
  motion_Fmqz9xzPjP,
  plugin_wy0B721ODc,
  clickOutsideDirective_JuSoe6vGeC,
  fetchGlobalData_EOfhKKMol6,
  onLanguageSwitch_Zq3lupusSn,
  sentry_client_shVUlIjFLk
]