import type { MultilinkStoryblok } from '@/types'
import { addSlashInLink, removeTrailingSlash } from '@/utils/helpers'

export const sanitizeInternalLink = (url: string) => {
  let sanitizedUrl = url.replace('%20', '')
  sanitizedUrl = addSlashInLink(sanitizedUrl)
  sanitizedUrl = removeTrailingSlash(sanitizedUrl)

  return decodeURI(
    sanitizedUrl.replace('/de/de/', '/de/').replace('/en/en/', '/en/'),
  ).trim()
}

const useStoryblokLink = (storyblokLink?: MultilinkStoryblok) => {
  const { locale } = useI18n()
  const { availableLocaleRoute } = useAvailableLocalePath()
  const anchorTag = computed(() => {
    return !storyblokLink?.anchor ? '' : `#${storyblokLink?.anchor}`
  })

  const target = computed(() => {
    return storyblokLink?.target !== undefined
      ? storyblokLink?.target
      : undefined
  })

  const removeLocaleFromLink = (link: string) => {
    return link?.replace(`/${locale.value}`, '')
  }

  const internalLink = computed(() => {
    const removedLocaleLink = removeLocaleFromLink(storyblokLink?.cached_url)
    return availableLocaleRoute(removedLocaleLink)
  })

  const cachedUrl = computed(() => {
    // url is used for external links, by default it is empty
    // cached_url is used for internal links
    if (storyblokLink?.url) return storyblokLink.url.trim()

    return sanitizeInternalLink(internalLink.value) + anchorTag.value
  })
  return {
    anchorTag,
    target,
    cachedUrl,
  }
}

export default useStoryblokLink
