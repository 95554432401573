const useBaseImage = () => {
  const re = /(?=120667\/.*?(\w+))/
  const arrayOfDimensions = (imageUrl: string) =>
    re.exec(imageUrl)?.filter(Boolean)[0].split('x')

  const intrinsicImageWidth = (imageUrl: string) => {
    return Number(arrayOfDimensions(imageUrl)?.[0]) || 600
  }

  const intrinsicImageHeight = (imageUrl: string) => {
    return Number(arrayOfDimensions(imageUrl)?.[1]) || 300
  }

  return {
    intrinsicImageWidth,
    intrinsicImageHeight,
  }
}

export default useBaseImage
