<template>
  <NuxtLink
    :to="to || cachedUrl"
    :target="target"
    :disabled="disabled"
    v-bind="$attrs"
  >
    <slot>link</slot>
  </NuxtLink>
</template>

<script lang="ts" setup>
import type { MultilinkStoryblok } from '@/types'
interface Props {
  address?: MultilinkStoryblok
  disabled?: boolean
  target?: string
  to?: string
}
const props = withDefaults(defineProps<Props>(), {
  address: undefined,
  target: '_self',
  disabled: false,
  to: undefined,
})

const { cachedUrl } = useStoryblokLink(props.address)
</script>
