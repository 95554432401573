<template>
  <div v-editable="blok" class="cta-block">
    <BaseNuxtImage
      class="cta-block__image"
      :src="blok.image.filename || '/'"
      :alt="blok.image.alt"
      loading="lazy"
    />
    <BaseButton
      :title="blok.button_text"
      @click="scrollTo('#form-section', 40)"
    >
      <template #text>
        {{ blok.button_text }}
      </template>
    </BaseButton>
    <div class="cta-block__background-border" />
  </div>
</template>

<script>
import { scrollTo } from '@/utils/helpers'

export default {
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      scrollTo,
    }
  },
}
</script>

<style lang="scss" scoped>
.button {
  display: block;
  margin: -14px auto 56px;
}

.cta-block {
  position: relative;
  z-index: 0;
  height: 100%;
  width: 100%;
  margin-top: 34px;
  min-width: 405px;

  &__image {
    display: block;
    width: 100%;
    max-width: 343px;
    margin: 0 auto;
    transform: translateX(-7.7%);
    z-index: 2;
  }

  &__background-border {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 88%;
    border: 1px solid var(--gray-400);
    border-radius: 16px;

    @media (min-width: $breakpoint-lg) {
      top: 56px;
      bottom: unset;
      height: 505px;
    }
  }
}
</style>
