<template>
  <li class="legal-item">
    <div
      :id="slugifyItem(blok.title)"
      v-editable="blok"
      class="legal-item__list-item"
    >
      <h3 class="legal-item__title">
        {{ blok.title }}
      </h3>
    </div>
    <div class="legal-item__richtext">
      <StoryblokComponent
        v-for="childBlok in blok.blocks"
        :key="childBlok._uid"
        :blok="childBlok"
      />
    </div>
  </li>
</template>

<script>
import { slugify } from '@/utils/helpers'
export default {
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      slugifyItem: (slug) => slugify(slug),
    }
  },
}
</script>

<style lang="scss" scoped>
.legal-item {
  position: relative;
  overflow-x: hidden;

  &__list-item {
    scroll-margin-top: 100px;
    counter-reset: h5;
    font-weight: var(--font-weight-paragraph-bold);
    font-size: var(--font-size-heading-3);
    color: var(--color-text);
  }

  &__title {
    display: inline-flex;
    padding-left: 3rem;

    &::before {
      position: absolute;
      left: 0;
      content: counter(h3) '.';
      counter-increment: h3;
    }
  }

  &__item {
    width: 100%;
    height: 300px;
    overflow: hidden;
    border-radius: 16px;
    box-shadow:
      0 13px 27px -5px rgb(50 50 93 / 25%),
      0 8px 16px -8px rgb(0 0 0 / 30%),
      0 -6px 16px -6px rgb(0 0 0 / 3%);

    img {
      object-fit: cover;
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &--sticky {
    position: sticky !important;
    top: 0;
    padding: 20px;

    @media (min-width: $breakpoint-lg) {
      position: relative;
    }
  }
}
</style>
<style lang="scss">
.legal-item__richtext .richtext {
  b span {
    font-weight: var(--font-weight-bold) !important;
  }

  h5 {
    position: relative;
    padding-left: 36px;
    margin: 32px 0 16px;
    counter-reset: h6;

    @media (min-width: $breakpoint-lg) {
      padding-left: 40px;
      margin: 40px 0 20px;
    }

    &::before {
      position: absolute;
      left: 0;
      content: counter(h3) '.' counter(h5) '.';
      counter-increment: h5;
    }
  }

  h6 {
    position: relative;
    padding-left: 48px;
    margin: 30px 0 16px;

    @media (min-width: $breakpoint-lg) {
      margin: 40px 0 20px;
    }

    &::before {
      position: absolute;
      left: 0;
      content: counter(h3) '.' counter(h5) '.' counter(h6) '.';
      counter-increment: h6;
    }
  }
}
</style>
